import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import logo from '../images/CC-001.png';

import crewsock_solid_black from '../images/crewsock_0001/solids/sock-3dcomp-solid-black-000000.png';
import crewsock_solid_white from '../images/crewsock_0001/solids/sock-3dcomp-solid-white-ffffff.png';
import crewsock_solid_gray from '../images/crewsock_0001/solids/sock-3dcomp-solid-gray-333333.png';
import crewsock_solid_red from '../images/crewsock_0001/solids/sock-3dcomp-solid-red-990000.png';
import crewsock_solid_green from '../images/crewsock_0001/solids/sock-3dcomp-solid-green-009900.png';
import crewsock_solid_blue from '../images/crewsock_0001/solids/sock-3dcomp-solid-blue-000099.png';
import crewsock_solid_purple from '../images/crewsock_0001/solids/sock-3dcomp-solid-purple-660066.png';

import pulloverhoodie_white from '../images/pulloverhoodie_0001/graphics/Hoodie_Mockup-white.png';
import pulloverhoodie from '../images/pulloverhoodie_0001/graphics/Hoodie_Mockup.png';
import pulloverhoodie_coozie from '../images/pulloverhoodie_0001/graphics/coozi.png';
import pulloverhoodie_coozie_blood from '../images/pulloverhoodie_0001/graphics/coozi-blood.png';
import pulloverhoodie_coozie_aqua from '../images/pulloverhoodie_0001/graphics/coozi-aqua.png';

import crewsock_graphic_wLoL from '../images/crewsock_0001/graphics/sock-3dcomp-LoLwhite.png';
import crewsock_graphic_redLoL from '../images/crewsock_0001/graphics/sock-3dcomp-LoLred.png';
import crewsock_graphic_blkLoL from '../images/crewsock_0001/graphics/sock-3dcomp-LoLblack.png';

import crewsock_graphic_outsideblk from '../images/crewsock_0001/graphics/sock-3dcomp-outsideblack.png';

import topc from '../images/top-cat.png';
import bottomc from '../images/bottom-cat.png';
import cloud2 from '../images/cloud2.png';

import React, { Component } from "react";
import { Link } from "gatsby";
import Nav from '../components/Nav';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class App extends Component {
  constructor() {
    super();

    this.max_quantity = 5

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNav = this.handleSubmitNav.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.form = React.createRef();
    this.orders = React.createRef();
    this.display = React.createRef();

    this.productChange = this.productChange.bind(this);

    /* 
    PNG export 50% -> 1500 x 1500 -> https://tinypng.com/
    */
    this.hoodie = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Pullover Hoodie',
      desc: 'our very own',
      variant: [
        {
          name: 'Coozi Rara',
          img: pulloverhoodie_coozie
        },
        {
          name: 'Coozi Aqua',
          img: pulloverhoodie_coozie_aqua
        },
        {
          name: 'Coozi Blood',
          img: pulloverhoodie_coozie_blood
        },
        {
          name: 'Black',
          img: pulloverhoodie
        },
        {
          name: 'White',
          img: pulloverhoodie_white
        },
      ],
      size: ['Extra Small', 'Small', 'Medium', 'Large', 'Extra Large']
    }

    this.crew_sock_solid = {
      id: 'crew_sock_solid',
      pushid: 'crewsock_0001',
      name: 'Crew Socks',
      desc: 'Solid Colors',
      variant: [
        {
          name: 'Solid Black',
          img: crewsock_solid_black
        },
        {
          name: 'Solid White',
          img: crewsock_solid_white
        },
        {
          name: 'Solid Red',
          img: crewsock_solid_red
        },
        {
          name: 'Solid Green',
          img: crewsock_solid_green
        },
        {
          name: 'Solid Blue',
          img: crewsock_solid_blue
        },
        {
          name: 'Solid Purple',
          img: crewsock_solid_purple
        },
        {
          name: 'Solid Gray',
          img: crewsock_solid_gray
        },
      ],
      size: ['One Size']
    }

    this.crew_sock_graphic = {
      id: 'crew_sock_graphic',
      pushid: 'crewsock_0001',
      name: 'Crew Socks',
      desc: 'Graphics',
      variant: [
        {
          name: 'Outside Clothes Black',
          img: crewsock_graphic_outsideblk
        },

        {
          name: 'Loyalty over Love White',
          img: crewsock_graphic_wLoL
        },
        {
          name: 'Loyalty over Love Black',
          img: crewsock_graphic_blkLoL
        },
        {
          name: 'Loyalty over Love Red',
          img: crewsock_graphic_redLoL
        },
      ],
      size: ['One Size']
    }

    this.inventory = [
      this.hoodie,
      this.crew_sock_solid,
      this.crew_sock_graphic,
    ]


    var products = {}
    for (let product = 0; product < this.inventory.length; product++) {
      products[this.inventory[product].id] = {
        quantity: 0,
        size: this.inventory[product].size[0],
        variant: this.inventory[product].variant[0].name,
        style: this.inventory[product].size[0] + ' - ' + this.inventory[product].variant[0].name,
      }
    }

    this.state = {
      active: false,
      isCheckout: false,
      orders: [],
      products,
      productsDisplay: '',
      iterMid: 0,
      iterTop: 0,
      iterMS: 1000,
      bgcolor: this.color[0],
      fgcolor: '#FFFFFF',
    }
  }

  isTopPage() {
    return (window.scrollY < 2);
  }

  isBottomPage() {
    return (window.scrollY > 3800); //mar 13 2022
  }

  nextColor() {

    if (this.isBottomPage())
      return "#000040";

    if (this.isTopPage()) {

      this.setState({ iterTop: (this.state.iterTop > this.color.length) ? 0 : this.state.iterTop + 1 });

      return this.color[this.state.iterTop];
    }

    //stick for now
    //return "#ffbdbd"
    this.setState({ iterMid: (this.state.iterMid > this.middlecolor.length) ? 0 : this.state.iterMid + 1 });
    return this.middlecolor[this.state.iterMid];


  }

  nextFgColor() {

    if (this.isBottomPage())
      return "#FFFFFF";

    else return "#000000";
  }

  colorSwatch = () => {
    this.setState({ bgcolor: this.nextColor(), fgcolor: this.nextFgColor() })
  }


  middlecolor = [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#ffbdbd',
    '#FFFFFF',
    '#FFFFFF',
    '#ffbdbd',
    '#ffbdbd',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#FFFFFF',
    '#FFFFFF',
    '#ffccbb',
    '#ffccbb',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#5c04c5',
    '#5c04c5',
    '#5c04c5',
  ];

  color = [
    '#FFFFFF',
    '#FFFFFF',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#FFFFFF',
    '#FFFFFF',
    '#ffccbb',
    '#ffccbb',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#5c04c5',
    '#5c04c5',
    '#5c04c5',
  ];


  handleSubmitNav = (event) => {
    this.handleSubmit();
    this.form.current.submit()
  }

  handleClear = (event) => {
    sessionStorage.clear()
    this.setState({orders: []})
  }

  handleSubmit = (event) => {

    var pushOrder = ''
    var pushDisplay = ''

    if (this.state.orders.length === 0) {
      alert("cart is empty")
      event.preventDefault()
      return;
    }
    else
      this.setState({isCheckout: true})

    for (let i = 0; i < this.state.orders.length; i++) {
      pushOrder = this.state.orders[i].push + ", " + pushOrder;
      pushDisplay = this.state.orders[i].display + ", " + pushDisplay;
    }

    this.orders.current.value = pushOrder
    this.display.current.value = pushDisplay
  }

  addProduct(id, pushid, name, style, quantity) {

    if (this.state.orders.length === 0)
      gsap.from("#navbarr", { duration: 1, opacity: 0 });

    const product =
    {
      id: id,
      date: new Date(),
      name: name,
      style: style,
      quantity: quantity,
      push: pushid + " " + style + " " + quantity,
      display: style + ' - ' + name,
    }

    window.sessionStorage.setItem('orders', JSON.stringify([product, ...this.state.orders]))

    this.setState({
      productsDisplay: 'Qty: ' + quantity + ' - ' + style + ' - ' + name + '<br/>' + this.state.productsDisplay,
      orders: [product, ...this.state.orders]
    })
  }

  productChange = (event, product) => {
    let btn
    if (!event.target)
      btn = event
    else {
      btn = event.target.value
    }

    const id = product.id
    const pushid = product.pushid
    const name = product.name
    let quantity = this.state.products[id].quantity;
    let variant = this.state.products[id].variant;
    let size = this.state.products[id].size;
    let style = size + " - " + variant;
    var products = this.state.products

    if (btn === 'confirm') {
      if (quantity <= 0)
        return;

      this.addProduct(id, pushid, name, style, quantity);

      products[id] = {
        quantity: 0,
        size: size,
        variant: variant,
        style: style,
      }

      this.setState({
        products
      })

    }
    else {
      if (btn === 'up') {
        if (quantity < this.max_quantity)
          quantity = parseInt(quantity) + 1;
      }
      else if (btn === 'down') {
        if (quantity > 0)
          quantity = parseInt(quantity) - 1;
      }
      else if (Number.isInteger(btn)) { //variant

        variant = product.variant[btn].name
        if (quantity === 0)
          quantity = parseInt(quantity) + 1;
      }
      else if (btn) //size
      {
        size = btn
      }

      style = size + " " + variant


      products[id] = {
        quantity: quantity,
        variant: variant,
        size: size,
        style: style
      }

      this.setState({
        products
      })
    }
  }
  componentDidMount() {
    setInterval(this.colorSwatch, this.state.iterMS);
    gsap.registerPlugin(ScrollTrigger)
    gsap.from("#top-logo", {
      duration: 3,
      opacity: 0,
      yPercent: -100
    });


    gsap.from("#topcat", {
      scrollTrigger: {
        trigger: "#topcat",
        scrub: 4, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      opacity: 0,
      yPercent: -100
    });

    const savedCart = window.sessionStorage.getItem('orders')
    if (savedCart != undefined) {
      this.setState({ orders: JSON.parse(savedCart) })
    }
  }

  componentWillUnmount() {
    clearInterval(this.colorSwatch);
  }

  render() {

    const style = {
      backgroundColor: this.state.bgcolor,
      color: this.state.fgcolor
    }
    return (
      <div>
        <title>In-House | Cozy Club</title>
        <div id="navbarr">
          <Nav onClick={this.handleSubmitNav} cart={this.state.orders.length} /></div>
        <div style={style} className={styles.container}>
          <main className={styles.main}>
            <div id="top-logo" >
              <img className={styles.rogo} src={logo} alt="" width={400} height={400} />

              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            </div>
            <div>
              <img className={styles.cloud3} src={cloud2} alt="" />
              <img className={styles.cloud4} src={cloud2} alt="" />
              <img className={styles.cloud7} src={cloud2} alt="" />
            </div>
            <div className={styles.description}>

              {Array.from({ length: this.inventory.length }, (value, product) =>
                <div key={product} className={styles.spacer}>
                  <span className={styles.title} >
                    {this.inventory[product].name}
                  </span>
                  <br />
                  {this.inventory[product].desc}
                  <br />
                  <br />
                  <div onClick={() => this.setState({ active: true })}>
                    <div>
                      <Carousel autoPlay={!this.state.active} thumbWidth={120} onChange={e => this.productChange(e, this.inventory[product])} className={styles.clip} swipeable={false} animationHandler={"fade"} infiniteLoop={true} useKeyboardArrows={true}>
                        {Array.from({ length: this.inventory[product].variant.length }, (value, index) => <img key={index} src={this.inventory[product].variant[index].img} alt="" />)}
                      </Carousel>
                    </div>
                    <h4>Style:<br />{this.state.products[this.inventory[product].id].variant}<br />{this.state.products[this.inventory[product].id].size}<br />
                      <label>Quantity: </label><button value="down" onClick={e => this.productChange(e, this.inventory[product])} className={styles.card}>-</button>{this.state.products[this.inventory[product].id].quantity}<button value="up" onClick={e => this.productChange(e, this.inventory[product])} className={styles.card}>+</button><br />
                      <label>Size: </label><select value={this.state.products[this.inventory[product].id].size} onChange={e => this.productChange(e, this.inventory[product])}>
                        {Array.from({ length: this.inventory[product].size.length }, (value, index) => <option key={index} value={this.inventory[product].size[index]}> {this.inventory[product].size[index]}</option>)}
                      </select><br />
                      <button className={styles.card} value="confirm" onClick={e => this.productChange(e, this.inventory[product])}>Add to Cart</button>
                      <br />
                    </h4></div>
                </div>
              )}


              <center><form action="https://server.cozyclub.us/create-checkout-session" ref={this.form} onSubmit={this.handleSubmit} method="POST">
                <input type="hidden" name="orders" id="orders" ref={this.orders} />
                <input type="hidden" name="display" id="display" ref={this.display} />
                <h6><div className="content" dangerouslySetInnerHTML={{
                  __html:
                    this.state.productsDisplay
                }}></div></h6>


                <div>
                  <button className={styles.card} id="btnSubmit">Checkout</button></div>
              </form>
                <div>
                  <button className={styles.card} onClick={this.handleClear}>Clear Cart</button></div>
              </center>
            </div>
            <div id="bottom">
              <br /><br /><br />
              <div id="topcat" className={styles.topcat}>
                <img className={styles.topwrapper} src={topc} alt=""></img><br />
              </div>
              <img id="bottomcat" className={styles.wrapper} src={bottomc} alt=""></img>
            </div>
            <div className={styles.description}>

              <div id="club" className={styles.title}>
                Cozy Club&trade;
              </div>
              <br />Our mission is to provide
              <br />comfortable garments
              <br />to the community as a whole.
              <br />
              <br />Fabrics that can be worn inside and out.<br />
              <br />We donate a pair of socks
              <br /> for EVERY order
              <br />
              <br />Please reach out to us!
              <br />
              <br />Homeless shelters
              <br />Community outreach
              <br />General inquiries
            </div>
          </main>


          <a
            href="https://instagram.com/cozierclothing"
          >
            <footer className={styles.footer}>
              Cozier Clothing LLC
            </footer>
          </a>

        </div>
      </div>
    );
  }
}